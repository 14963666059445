import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import bootstrap from "../../node_modules/bootstrap/dist/js/bootstrap";
import { Fancybox } from "../../node_modules/@fancyapps/ui/dist/fancybox/fancybox.esm";
import { Carousel } from "../../node_modules/@fancyapps/ui/dist/carousel/carousel.esm";
import { Autoplay } from "../../node_modules/@fancyapps/ui/dist/carousel/carousel.autoplay.esm";
import AOS from "../../node_modules/aos/dist/aos";
import { smoothScroll } from "./components/smooth-scroll.js";

// Initialise our components on jQuery.ready…
jQuery(function ($) {

    //ANIMATE ON SCROLL
    AOS.init({
        duration: 1400
    });

    //FANCYAPP CAROUSEL
    if ($('#myCarousel').length) {
        const container = document.getElementById("myCarousel");
        const options = {
            Autoplay: {
                timeout: 6000,
            }
        };
        new Carousel(container, options, { Autoplay });
    }

});